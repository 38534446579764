.error-toast {
  position: absolute;
  top: 0;
  right: 0;
}

.error-toast-header {
  background-color: #dc3545;
  color:white;
}

.error-toast-header span {
  color:white;
}

.custom-error {
  z-index: 100;
  position: sticky;
  /* top: 10px; */
}

.custom-error-container {
  position: stickyabsolute;
  top:0;
}